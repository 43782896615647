import React from 'react'
import GoogleMapReact from 'google-map-react'
import Marker from '../icons/marker.svg'

const defaultProps = {
  center: {
    lat: 50.095150,
    lng: 19.92423
  },
  zoom: 16
}

const getMapOptions = {
    // disableDefaultUI: true,
    mapTypeControl: true,
    // streetViewControl: true,
    styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
  };

const AnyReactComponent = ({ text }) => <div style={{position: 'relative'}}><div style={{ width: '48px', height: '72px', position: 'absolute', bottom: '0%', left: '0%', }}><Marker /></div></div>

const GoogleMap = () => (
  <div style={{ height: '100%', width: '100%', minHeight: '500px' }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyAoXgchlSbW-QQPmfWRh5Qsk_0fQMMSpoA' }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
      options={getMapOptions}
    >
      <AnyReactComponent
        lat={50.095400}
        lng={19.923732}
        text={'Biuro Rachunkowe Jolanta Saska'}
      />
    </GoogleMapReact>
  </div>
)

export default GoogleMap
